import { theme } from "antd";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSubMenu, editSubMenu } from "../features/menu/menuActions";


export default function useMenuModal() {
  const dispatch = useDispatch();
  const [inpVal, setInpVal] = useState("");
  const formRef = useRef(null);
  const { error, loading } = useSelector((state) => state?.menu);
  const { results: subMenus } = useSelector((state) => state.menu.subMenu);
  const { useToken } = theme;
  const {
    token: { Modal: modal, Button: button },
  } = useToken();
  const [formData, setFormData] = useState([])
  const [isChecked, setIsChecked] = useState(false)
  const [isWeedmapsChecked, setWeedmapsChecked] = useState(false)
  

  const oninpChnage = (e) => {
    setInpVal(e.target.value);
  };

  const onChackboxChange = (e) => {
    setIsChecked(e.target.checked)
  }

  const onWeedmapsChackboxChange = (e) => {
    setWeedmapsChecked(e.target.checked)
  }

  return {
    modal,
    button,
    inpVal,
    oninpChnage,
    setInpVal,
    formRef,
    error,
    loading,
    dispatch,
    addSubMenu,
    editSubMenu,
    subMenus,
    formData,
    setFormData,
    isChecked,
    onChackboxChange,
    onWeedmapsChackboxChange,
    setIsChecked,
    isWeedmapsChecked,
    setWeedmapsChecked
  };
}
